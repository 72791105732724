import React, { ReactNode, useState } from "react";
import { Text, Flex, Box, Heading } from "theme-ui";

import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT } from "../strings";
import { useLiquity } from "../hooks/LiquityContext";
import { shortenAddress } from "../utils/shortenAddress";

import { Icon } from "./Icon";
import { useBondView } from "./Bonds/context/BondViewContext";
import { useBondAddresses } from "./Bonds/context/BondAddressesContext";
import { Dialog } from "./Dialog";

const select = ({ accountBalance, lusdBalance, lqtyBalance }: LiquityStoreState) => ({
  lusdBalance,
  accountBalance,
  lqtyBalance
});

const usdaTokenAddress = '0x45c99a7C471212db282C97ceE79056293a3C2D80';

export const UserAccount: React.FC = () => {
  const { account } = useLiquity();
  const { accountBalance, lusdBalance: realLusdBalance, lqtyBalance } = useLiquitySelector(select);
  const { bLusdBalance, lusdBalance: customLusdBalance } = useBondView();
  const { LUSD_OVERRIDE_ADDRESS } = useBondAddresses();
  const [visible, setVisible] = useState(false);

  const lusdBalance = LUSD_OVERRIDE_ADDRESS === null ? realLusdBalance : customLusdBalance;

  return (
    <Box sx={{ display: ["none", "flex"], color: "#dfdfdf", position: "relative" }}>
      <Flex sx={{ alignItems: "center" }}>
        <Icon name="user-circle" size="lg" />
        <Flex sx={{ ml: 3, mr: 4, flexDirection: "column" }}>
          <Heading sx={{ fontSize: 1 }}>Connected as</Heading>
          <Text as="span" sx={{ fontSize: 1 }}>
            {shortenAddress(account)}
          </Text>
        </Flex>
      </Flex>

      <Flex sx={{ alignItems: "center" }}>
        <Icon name="wallet" size="lg" />
        <Flex sx={{ ml: 3, flexDirection: "column", cursor: "pointer" }} onClick={() => {
          navigator.clipboard.writeText(usdaTokenAddress);
          setVisible(true);
          setTimeout(() => {
            setVisible(false);
          }, 3000)
        }}>
          <Heading sx={{ fontSize: 1 }}>USDA</Heading>
          <Text sx={{ fontSize: 1 }}>0x45c...</Text>
        </Flex>
        {([
          ["ETH", accountBalance],
          [GT, Decimal.from(lqtyBalance)],
          ["bUSDA", Decimal.from(bLusdBalance || 0)]
        ] as const).map(([currency, balance], i) => (
          <Flex key={i} sx={{ ml: 3, flexDirection: "column" }}>
            <Heading sx={{ fontSize: 1 }}>{currency}</Heading>
            <Text sx={{ fontSize: 1 }}>{balance.prettify()}</Text>
          </Flex>
        ))}
      </Flex>
      {visible && <Box sx={{ position: "fixed" }}>
        <Dialog title={"Copy to clipboard"} intent="success" onCancel={() => {
          setVisible(false)
        }} />
      </Box>}
    </Box>
  );
};
